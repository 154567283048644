
import { defineComponent, onMounted, ref, computed, watch } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useStore, useRouter, usePermission } from '@/utils/composables'

import MyndshftInput from '@/components/form/input.vue'
import WorklistFilter from '@/components/worklist/worklist-filter.vue'
import { MyndshftPanel } from '@myndshft/panel'
import WorklistPhone from '@/components/worklist/item/phone.vue'
import WorklistFax from '@/components/worklist/item/fax.vue'
import WorklistPortal from '@/components/worklist/item/portal.vue'
import WorklistPaConfigurationSelection from '@/components/worklist/item/pa-configuration-selection.vue'

import WorklistPADetermination from '@/components/worklist/item/pa-determination.vue'
import WorklistStatus from '@/components/worklist/item/status.vue'
import WorklistGuideline from '@/components/worklist/item/guideline.vue'
import RequirementSet from '@/components/worklist/requirements/requirement-set.vue'
import StatusSelection from '@/components/worklist/controls/status-selection.vue'
import PublishButton from '@/components/worklist/controls/publish-button.vue'
import PasteRequirementsButton from '@/components/worklist/controls/paste-requirements-button.vue'
import DeleteButton from '@/components/worklist/controls/delete-button.vue'
import DeleteRequirementsButton from '@/components/worklist/controls/delete-requirements-button.vue'
import CodeDescriptionsButton from '@/components/worklist/controls/code-description-button.vue'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'
import ShowConflictsButton from '@/components/worklist/controls/show-conflicts.vue'

import {
	fetchWorklist,
	updateWorklist,
	fetchWorklistItems,
	updateWorklistItem,
	publishWorklistItems,
	deleteWorklistItems,
	fetchWorklistItem,
	fetchForms,
	fetchWorklistDataFilters,
	fetchSubmissionConfigurations,
	fetchSubmissionConfiguration,
} from '@/service/worklist.http'

import Swal from 'sweetalert2'

import { logRocketSetEvent, logRocketBulkStatusEvent } from '@/utils/logrocket'
import { ActiveStatus, IResponseFaxMethod } from '@myndshft/types-mc-api'
import {
	AuthRequirementName,
	ContactMethodTypeName,
	CopyPasteType,
	IGuideline,
	liftToTableItem,
	RequirementType,
} from '@server/api/models/fe/shared'
import {
	createRequirementSet,
	updateRequirementSet,
} from '@/service/requirements.http'
import { fetchPlacesOfService } from '@/service/shared.http'
import {
	IContactFaxMethod,
	IWorklist,
	IWorklistFilterData,
	IWorklistItem,
	IWorklistItemFilter,
	IWorklistItemsData,
	WorklistItemStatus,
} from '@server/api/models/fe/worklist'
import { ITableProp } from '@server/api/models/fe/shared'
import { IRequirementSet } from '@server/api/models/fe/requirements'
import { IForm, ISubmissionConfiguration } from '@/models/worklists/outcome'

type ITableWorklistItem = ITableProp & IWorklistItem

export default defineComponent({
	name: '',
	components: {
		[MyndshftInput.name]: MyndshftInput,
		[WorklistFilter.name]: WorklistFilter,
		[WorklistPhone.name]: WorklistPhone,
		[WorklistFax.name]: WorklistFax,
		[WorklistPortal.name]: WorklistPortal,
		[WorklistPaConfigurationSelection.name]: WorklistPaConfigurationSelection,
		[WorklistPADetermination.name]: WorklistPADetermination,
		[WorklistStatus.name]: WorklistStatus,
		[WorklistGuideline.name]: WorklistGuideline,
		[StatusSelection.name]: StatusSelection,
		[RequirementSet.name]: RequirementSet,
		[PublishButton.name]: PublishButton,
		[PasteRequirementsButton.name]: PasteRequirementsButton,
		[DeleteButton.name]: DeleteButton,
		[DeleteRequirementsButton.name]: DeleteRequirementsButton,
		[CodeDescriptionsButton.name]: CodeDescriptionsButton,
		ModifiedbyTooltip,
		MyndshftPanel,
		ShowConflictsButton,
	},
	setup() {
		const store = useStore()
		const router = useRouter()
		const permission = usePermission()

		const worklistId = ref(router.currentRoute.params.id || '')
		const worklist = ref<IWorklist>({} as IWorklist)
		const isEditingName = ref(false)
		const isPublishing = ref(false)
		const isPasting = ref(false)
		const isDeleting = ref(false)
		const isDeletingRequirements = ref(false)
		const tempName = ref('')
		const headers = [
			{
				text: 'Status',
				value: 'status',
			},
			{
				text: '',
				value: 'data-table-expand',
				sortable: false,
			},
			{
				text: 'Active',
				value: 'active',
				sortable: false,
			},
			{
				text: 'Payer',
				value: 'payer.name',
			},
			{
				text: 'Plan',
				value: 'plan.type',
			},
			{
				text: 'Code',
				value: 'procedureCode',
			},
			{
				text: 'Category',
				value: 'procedureCategory',
				width: '150px',
			},
			{
				text: 'Guidelines',
				value: 'guidelines',
				sortable: false,
				width: '150px',
			},
			{
				text: 'Contacts',
				value: 'contacts',
				sortable: false,
				width: '180px',
			},
			{
				text: 'PA Requirements',
				value: 'authRequirement',
				width: '150px',
				sortable: false,
			},
			{
				text: 'PA Configuration',
				value: 'configuration',
				width: '150px',
				sortable: false,
			},
			{
				value: 'modified',
				sortable: false,
			},
		]

		const worklistItems = ref<ITableWorklistItem[]>([])
		const totalItems = ref(0)
		const isLoadingNameChange = ref(false)
		const user = computed(() => store.state.appUser)
		const worklistTable = ref()
		const pageLoading = ref(true)
		const page = ref(0)
		const sort = ref('payer')
		const sortOrder = ref('asc')
		const filterOptions = ref<IWorklistItemFilter>({} as IWorklistItemFilter)
		const displayAuthRequirement = AuthRequirementName
		const displayContactMethod = ContactMethodTypeName
		const canEditDetail = ref<boolean>(false)
		const isBulkUpdating = ref(false)
		const worklistItemStatus = WorklistItemStatus
		const formOptions = ref<IForm[]>([])
		const submissionConfigurationOptions = ref<ISubmissionConfiguration[]>([])
		const worklistFilterData = ref<IWorklistFilterData>(
			{} as IWorklistFilterData
		)
		const showCodeDescriptions = ref(false)

		const formIds = computed<string[]>(
			() =>
				worklistItems.value?.map(
					(item: any) => item?.outcome?.authSubmissionFormId || ''
				) || []
		)

		// TODO - update this to the correct id
		const submissionConfigurationIds = computed<string[]>(
			() =>
				worklistItems.value?.map(
					(item: any) => item?.outcome?.authSubmissionPortalId || ''
				) || []
		)

		const selectedWorklistItems = computed<IWorklistItem[]>(() => {
			return worklistItems.value.filter(item => item.isSelected)
		})

		const isBulkEditing = computed(() => {
			return !!selectedWorklistItems.value.length
		})

		const haveSamePayer = computed(() => {
			const firstPayerId = selectedWorklistItems.value[0]?.payer.id ?? ''
			return selectedWorklistItems.value.every(
				item => item.payer.id === firstPayerId
			)
		})

		const haveSamePlan = computed(() => {
			// check if selected items share same payer/plan combo
			const firstPlanId = selectedWorklistItems.value[0]?.plan.id ?? ''
			return selectedWorklistItems.value.every(
				item => item.plan.id === firstPlanId
			)
		})

		const haveSamePayerPlan = computed(() => {
			return haveSamePayer.value && haveSamePlan.value
		})

		const haveSameStatus = computed(() => {
			// checks if all selected items have the same status
			const firstStatus = selectedWorklistItems.value[0]?.status
			return selectedWorklistItems.value.every(
				item => item.status === firstStatus
			)
		})

		const allSelectedItemsInProgress = computed(() => {
			const requestedItems = worklistItems.value.filter(item => item.isSelected)
			return requestedItems.every(
				item => item.status === worklistItemStatus.IN_PROGRESS
			)
		})

		const canPublish = computed(() => {
			const requestedItems = worklistItems.value.filter(item => item.isSelected)
			return !!(
				permission.$canPublishWorklist() &&
				requestedItems.length &&
				requestedItems.every(
					item =>
						item.status === WorklistItemStatus.IN_REVIEW ||
						item.status === WorklistItemStatus.FAILED_PUBLISH
				)
			)
		})

		const canDelete = computed(() => {
			const requestedItems = worklistItems.value.filter(item => item.isSelected)
			return !!(
				requestedItems.length &&
				permission.$canDeleteWorklistDetail() &&
				allSelectedItemsInProgress.value
			)
		})

		const canDeleteRequirements = computed(() => {
			return allSelectedItemsInProgress.value
		})

		const getPage = computed(() => {
			// Update table page which is indexed as 1-based
			return page.value + 1
		})

		const getWorklistItems = (callback: () => void) => {
			fetchWorklistItems(
				page.value,
				sort.value,
				sortOrder.value,
				worklistId.value,
				filterOptions.value
			).then((data: IWorklistItemsData) => {
				worklistItems.value = data.items
					.map(liftToTableItem)
					.map((item: any) => {
						item.isDisabled = item.status === WorklistItemStatus.PENDING_PUBLISH
						item.isSelected = selectedWorklistItems.value.find(selectedItem => {
							return selectedItem.id === item.id
						})
						return item
					})

				totalItems.value = data.total

				logRocketSetEvent('WorklistItemsLoadedEvent', {
					worklistName: worklist.value.name || '',
					totalItems: totalItems.value,
				})

				callback()
				checkFailedPublishItems()
			})
		}

		const checkFailedPublishItems = () => {
			const failedItems = worklistItems.value.filter(
				item => item.status === WorklistItemStatus.FAILED_PUBLISH
			)

			if (failedItems.length) {
				const message =
					failedItems.length > 1
						? `are ${failedItems.length} worklist items`
						: `is 1 worklist item`

				logRocketSetEvent('WorklistFailedPublishEvent', {
					worklistName: worklist.value.name,
					failedItems: failedItems.length,
					totalItems: totalItems.value,
				})

				toast({
					type: Alert.ERROR,
					title: `There ${message} that failed to publish`,
				})
			}
		}

		const updateName = () => {
			isLoadingNameChange.value = true
			updateWorklist(worklist.value)
				.then(() => {
					isLoadingNameChange.value = false
					isEditingName.value = false
					toast({
						type: Alert.SUCCESS,
						title: 'Succesfully changed Worklist Name',
					})
				})
				.catch(() => {
					toast({
						type: Alert.ERROR,
						title: 'There was an Error saving the Worklist Name',
					})
				})
		}

		const onToggleEditName = () => {
			isEditingName.value = !isEditingName.value
			if (!isEditingName.value) {
				worklist.value.name = tempName.value
			}
		}

		const updatePage = (tablePage: number) => {
			// Service page is 0-based; data table is 1-based
			page.value = tablePage - 1
			pageLoading.value = true
			scrollToTop()
			getWorklistItems(() => (pageLoading.value = false))
			const data = (worklistTable.value as any).$data
			data.selection = []
		}

		const formatSort = (tableSort: string) => {
			switch (tableSort) {
				case 'payer.name':
					return 'payer'
				case 'plan.type':
					return 'plan'
				case 'procedureCode':
					return 'procedure_code'
				case 'procedureCategory':
					return 'procedure_category'
				case 'status':
					return tableSort
				default:
					return ''
			}
		}

		const updateSortOrder = (orderDesc: boolean) => {
			// only receives true when sorting desc
			if (orderDesc) {
				sortOrder.value = 'desc'
				pageLoading.value = true
				scrollToTop()
				getWorklistItems(() => (pageLoading.value = false))
			}
		}

		const updateSort = (tableSort: string) => {
			// will be header value otherwise undefined which sets sorts to defaults
			if (tableSort) {
				sort.value = formatSort(tableSort)
			} else {
				sort.value = 'payer'
				sortOrder.value = 'asc'
			}
			pageLoading.value = true
			scrollToTop()
			getWorklistItems(() => (pageLoading.value = false))
		}

		const scrollToTop = () => {
			// If the data table exists, scroll to the top
			if (worklistTable.value) {
				const table = worklistTable.value as any
				table.$vuetify.goTo('.v-data-table tbody tr', {
					offset: 0,
					container: '.v-data-table__wrapper',
				})
			}
		}

		function onFilterChange(filters: IWorklistItemFilter) {
			filterOptions.value = filters
			pageLoading.value = true

			// The data has changed, reset the page
			page.value = 0

			scrollToTop()
			getWorklistItems(() => (pageLoading.value = false))
		}

		const onItemSelect = (item: ITableWorklistItem) => {
			item.isSelected = !item.isSelected
			worklistItems.value = [...worklistItems.value]
		}

		const onSelectAll = ({
			items,
			value,
		}: {
			items: ITableWorklistItem[]
			value: boolean
		}) => {
			items.forEach(item => {
				// Do not select pending publish items
				if (item.status !== WorklistItemStatus.PENDING_PUBLISH) {
					item.isSelected = value
				}
			})
			worklistItems.value = [...worklistItems.value]
		}

		const onStatusUpdate = (status: WorklistItemStatus) => {
			if (isBulkEditing.value) {
				const selectedItemsCopy = JSON.parse(
					JSON.stringify([...selectedWorklistItems.value])
				) as any[]

				logRocketSetEvent('WorklistBulkStatusUpdateEvent', {
					worklistName: worklist.value.name,
					status,
					selectedItems: selectedItemsCopy.length,
					totalItems: totalItems.value,
				})

				logRocketBulkStatusEvent(selectedItemsCopy, status)

				selectedItemsCopy.forEach((selectedItem: IWorklistItem) => {
					// Update item if status is in-progress or in-review if meets requirements
					if (
						status === WorklistItemStatus.IN_PROGRESS ||
						(status === WorklistItemStatus.IN_REVIEW &&
							selectedItem.summary.meetsRequirements)
					) {
						selectedItem.status = status
						updateItem(selectedItem)
					}
				})
			}
		}

		const onDeleteRequirementSet = (item: IWorklistItem) => {
			const itemCopy: IWorklistItem = JSON.parse(JSON.stringify(item))

			itemCopy.requirementSet = null
			updateItem(itemCopy)

			logRocketSetEvent('RequirementSetDeletedEvent', {
				worklistName: worklist.value.name,
			})
		}

		const onDeleteRequirements = () => {
			const requestedItems = worklistItems.value.filter(item => item.isSelected)
			const itemsLength = requestedItems.length

			// Launch confirmation dialog
			Swal.fire({
				title: 'Deleting Requirement Sets',
				html: `Are you sure you want to delete the requirements from <strong>${itemsLength}</strong> selected item${
					itemsLength === 1 ? '?' : 's?'
				}`,
				confirmButtonText: 'Delete',
				confirmButtonColor: '#ef233c',
				showCancelButton: true,
				customClass: {
					container: 'swal2-container-full',
				},
			}).then(result => {
				if (result.value) {
					isDeletingRequirements.value = true
					requestedItems.map(item => {
						onDeleteRequirementSet(item)
					})

					logRocketSetEvent('RequirementsDeletedEvent', {
						worklistName: worklist.value.name,
						items: itemsLength,
					})

					toast({
						type: Alert.INFO,
						title: `Succesfully deleted requirements.`,
						timer: 5000,
					})
					isDeletingRequirements.value = false
				}
			})
		}

		const onShowCodeDescription = (show: boolean) => {
			showCodeDescriptions.value = show
		}

		const allMatchingPayerPlan = computed(() => {
			const requestedItems = worklistItems.value.filter(item => item.isSelected)
			return requestedItems.every(item => {
				return (
					item.payer.id === store.state.copyPaste.payerId &&
					item.plan.id === store.state.copyPaste.planId &&
					item.payer.id === requestedItems[0].payer.id &&
					item.plan.id === requestedItems[0].plan.id
				)
			})
		})

		const isPasteRequirementSet = computed(() => {
			return store.state.copyPaste.type === CopyPasteType.REQUIREMENT_SET
		})

		const canPasteRequirements = computed(() => {
			return !!(
				isBulkEditing.value &&
				permission.$canEditWorklist() &&
				allMatchingPayerPlan.value &&
				isPasteRequirementSet.value &&
				allSelectedItemsInProgress.value
			)
		})

		const updateSet = (requirementSet: IRequirementSet) => {
			const updatedRequirementSet = JSON.parse(JSON.stringify(requirementSet))
			updateRequirementSet(updatedRequirementSet).then(value => {
				requirementSet = value
			})
		}

		const onPasteRequirements = () => {
			if (canPasteRequirements) {
				const requestedItems = worklistItems.value.filter(
					wlItem => wlItem.isSelected
				)
				const set = store.state.copyPaste.requirementSet
				requestedItems.map(item => {
					if (item.requirementSet) {
						onDeleteRequirementSet(item)
					}
					createRequirementSet().then(id => {
						const requirementSet: IRequirementSet = {
							id,
							type: RequirementType.BASIC,
							requirements: set.requirements,
							hasDefaultRequirement: set.hasDefaultRequirement,
						}
						updateSet(requirementSet)
						associateRequirements(id, item)

						logRocketSetEvent('CreateRequirementsFromPasteEvent', {
							worklistName: worklist.value.name,
							requirementsCount: set.requirements.length,
						})
					})
				})
				toast({
					type: Alert.INFO,
					title: `Succesfully pasted requirements.`,
					timer: 5000,
				})
				isPasting.value = false

				logRocketSetEvent('PasteRequirementsEvent', {
					worklistName: worklist.value.name,
					itemsCount: requestedItems.length,
				})
			}
		}

		const onPublish = () => {
			isPublishing.value = true
			const requestedItems = worklistItems.value
				.filter(item => item.isSelected)
				.map(item => item.id)

			let success = false

			publishWorklistItems(worklist.value.id, requestedItems)
				.then(() => {
					success = true

					logRocketSetEvent('PublishWorklistItemsEvent', {
						worklistName: worklist.value.name,
						itemsCount: requestedItems.length,
						totalItems: totalItems.value,
					})
				})
				.catch(error => {
					toast({
						type: Alert.ERROR,
						title: 'Error publishing worklist items',
						error,
						timer: 5000,
					})
				})
				.finally(() => {
					isPublishing.value = false

					fetchWorklist(worklistId.value)
						.then(() => {
							if (success) {
								getWorklistItems(() => {
									toast({
										type: Alert.INFO,
										title: `Publishing worklist items. Please refresh in a few minutes.`,
										timer: 5000,
									})
								})
							}
						})
						.catch(() => {
							if (success) {
								router.push('/worklist/list')

								logRocketSetEvent('WorklistCompleteEvent', {
									worklistName: worklist.value.name,
								})

								toast({
									type: Alert.INFO,
									title: `All worklist items are complete. Redirecting back to worklists.`,
									timer: 5000,
								})
							} else {
								toast({
									type: Alert.ERROR,
									title: 'This worklist does not exist.',
								})
							}
						})
				})
		}

		const onDeleteItems = () => {
			const requestedItems = worklistItems.value.filter(item => item.isSelected)
			const itemsLength = requestedItems.length

			// Launch confirmation dialog
			Swal.fire({
				title: 'Deleting Worklist Items',
				html: `Are you sure you want to delete the <strong>${itemsLength}</strong> selected item${
					itemsLength === 1 ? '?' : 's?'
				}`,
				confirmButtonText: 'Delete',
				confirmButtonColor: '#ef233c',
				showCancelButton: true,
				customClass: {
					container: 'swal2-container-full',
				},
			}).then(result => {
				if (result.value) {
					isDeleting.value = true
					let success = false

					deleteWorklistItems(worklist.value.id, requestedItems)
						.then(() => {
							success = true

							logRocketSetEvent('DeleteWorklistItemsEvent', {
								worklistName: worklist.value.name,
								itemsCount: requestedItems.length,
								totalItems: totalItems.value,
							})
						})
						.catch(error => {
							toast({
								type: Alert.ERROR,
								title: 'Error deleting worklist items',
								error,
								timer: 5000,
							})
						})
						.finally(() => {
							isDeleting.value = false

							fetchWorklist(worklistId.value)
								.then(() => {
									if (success) {
										getWorklistItems(() => {
											toast({
												type: Alert.SUCCESS,
												title: `Successfully deleted selected worklist item(s)`,
												timer: 5000,
											})
										})
									}
								})
								.catch(() => {
									if (success) {
										router.push('/worklist/list')

										toast({
											type: Alert.INFO,
											title: `All worklist items are deleted. Redirecting back to worklists.`,
											timer: 5000,
										})
									} else {
										toast({
											type: Alert.ERROR,
											title: 'This worklist does not exist.',
										})
									}
								})
						})
				}
			})
		}

		const replaceWorklistItem = (updatedItem: IWorklistItem) => {
			// Find existing worklist item to update
			const index = worklistItems.value.findIndex(
				worklistItem => worklistItem.id === updatedItem.id
			)
			const previousItem = worklistItems.value[index]

			// Update worklist item
			worklistItems.value.splice(
				index,
				1,
				Object.assign(previousItem, [updatedItem].map(liftToTableItem)[0], {
					isSelected: previousItem.isSelected,
				})
			)

			maintainSelections()
		}

		const onCheckItemRequirement = (item: IWorklistItem) => {
			fetchWorklistItem(worklistId.value, item.id).then(response => {
				replaceWorklistItem(response.item)
			})
		}

		const onActiveItem = (item: IWorklistItem) => {
			const itemCopy = JSON.parse(JSON.stringify(item))
			itemCopy.active = assignActiveItem(item)

			if (isBulkEditing.value) {
				// Update all selected items
				updateActiveItems(itemCopy)
			} else {
				updateItem(itemCopy)
			}
		}

		const assignActiveItem = (item: IWorklistItem): ActiveStatus => {
			if (item.active === ActiveStatus.ACTIVE) {
				return ActiveStatus.INACTIVE
			}
			return ActiveStatus.ACTIVE
		}

		const updateActiveItems = (item: IWorklistItem) => {
			const selectedItemsCopy = JSON.parse(
				JSON.stringify([...selectedWorklistItems.value])
			) as any[]

			selectedItemsCopy.forEach((selectedItem: IWorklistItem) => {
				selectedItem.active = item.active
				updateItem(selectedItem)
			})
		}

		function updateItem(item: IWorklistItem) {
			const notify =
				!!worklist.value.assigneeId &&
				worklist.value.assigneeId !== user.value.user?.id &&
				item.status === WorklistItemStatus.IN_PROGRESS
			updateWorklistItem(
				worklistId.value,
				notify,
				worklist.value.assigneeId,
				item
			)
				.then(updatedItem => {
					replaceWorklistItem(updatedItem)

					logRocketSetEvent('UpdateWorklistItemEvent', {
						worklistName: worklist.value.name,
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})
				})
				.catch(() => {
					toast({ type: Alert.ERROR, title: 'Worklist item failed to save.' })
				})
		}

		const setSelectedValue = (
			type: string,
			item: IWorklistItem,
			selectedItems: IWorklistItem[],
			values: {
				value: IContactFaxMethod | IGuideline | undefined
				isAdded: boolean
			}
		) => {
			switch (type) {
				case 'guideline': {
					selectedItems.forEach(selectedItem => {
						const index = selectedItem.outcome.assets.findIndex(
							guideline => guideline.id === values.value?.id
						)

						if (values.isAdded) {
							if (index < 0 && values.value) {
								selectedItem.outcome.assets.push(values.value as IGuideline)

								logRocketSetEvent('AddGuidelineEvent', {
									worklistName: worklist.value.name,
									guideline: values.value.name,
									payer: item.payer.name,
									plan: item.plan.type,
									procedureCode: item.procedureCode,
								})
							}
						} else {
							if (index > -1 && values.value) {
								selectedItem.outcome.assets.splice(index, 1)

								logRocketSetEvent('RemoveGuidelineEvent', {
									worklistName: worklist.value.name,
									guideline: values.value.name,
									payer: item.payer.name,
									plan: item.plan.type,
									procedureCode: item.procedureCode,
								})
							}
						}
					})
					break
				}
				case 'phone':
					selectedItems.forEach(selectedItem => {
						selectedItem.outcome.contactMethods.phone =
							item.outcome.contactMethods.phone
					})

					logRocketSetEvent('AddPhoneEvent', {
						worklistName: worklist.value.name,
						phone: item.outcome.contactMethods.phone,
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
				case 'fax': {
					selectedItems.forEach(selectedItem => {
						let index = -1
						if (selectedItem.outcome.contactMethods.faxes) {
							index = selectedItem.outcome.contactMethods.faxes.findIndex(
								fax => fax.id === values.value?.id
							)
						}

						if (values.isAdded) {
							if (index < 0 && values.value) {
								if (selectedItem.outcome.contactMethods.faxes) {
									selectedItem.outcome.contactMethods.faxes.push(
										values.value as unknown as IResponseFaxMethod
									)

									logRocketSetEvent('AddFaxEvent', {
										worklistName: worklist.value.name,
										fax: values.value.name,
										payer: item.payer.name,
										plan: item.plan.type,
										procedureCode: item.procedureCode,
									})
								}
							}
						} else {
							if (index > -1 && values.value) {
								if (selectedItem.outcome.contactMethods.faxes) {
									selectedItem.outcome.contactMethods.faxes.splice(index, 1)

									logRocketSetEvent('RemoveFaxEvent', {
										worklistName: worklist.value.name,
										fax: values.value.name,
										payer: item.payer.name,
										plan: item.plan.type,
										procedureCode: item.procedureCode,
									})
								}
							}
						}
					})
					break
				}
				case 'portal':
					selectedItems.forEach(selectedItem => {
						selectedItem.outcome.contactMethods.portal =
							item.outcome.contactMethods.portal
					})

					logRocketSetEvent('AddPortalEvent', {
						worklistName: worklist.value.name,
						portal: item.outcome.contactMethods.portal?.name,
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
				case 'preferredMethod':
					selectedItems.forEach(selectedItem => {
						selectedItem.outcome.preferredMethod = item.outcome.preferredMethod
					})

					logRocketSetEvent('AddPreferredMethodEvent', {
						worklistName: worklist.value.name,
						preferredMethod: item.outcome.preferredMethod,
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
				case 'authRequirement':
					selectedItems.forEach(selectedItem => {
						selectedItem.outcome.authRequirement = item.outcome.authRequirement
					})

					logRocketSetEvent('AddAuthRequirementEvent', {
						worklistName: worklist.value.name,
						authRequirement: item.outcome.authRequirement,
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
				case 'authSubmissionFormId':
					selectedItems.forEach(selectedItem => {
						selectedItem.outcome.authSubmissionFormId =
							item.outcome.authSubmissionFormId
						selectedItem.outcome.authSubmissionPortalId = null
					})

					logRocketSetEvent('AddAuthSubmissionFormIdEvent', {
						worklistName: worklist.value.name,
						authSubmissionFormId: item.outcome?.authSubmissionFormId || '',
						authSubmissionPortalId: item.outcome?.authSubmissionPortalId || '',
						submissionConfigurationId:
							item.outcome?.submissionConfigurationId || '',
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
				case 'authSubmissionPortalId':
					selectedItems.forEach(selectedItem => {
						selectedItem.outcome.authSubmissionPortalId =
							item.outcome.authSubmissionPortalId
						selectedItem.outcome.authSubmissionFormId = null
					})

					logRocketSetEvent('AddAuthSubmissionPortalIdEvent', {
						worklistName: worklist.value.name,
						authSubmissionFormId: item.outcome?.authSubmissionFormId || '',
						authSubmissionPortalId: item.outcome?.authSubmissionPortalId || '',
						submissionConfigurationId:
							item.outcome?.submissionConfigurationId || '',
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
				case 'submissionConfigurationId':
					selectedItems.forEach(selectedItem => {
						selectedItem.outcome.submissionConfigurationId =
							item.outcome.submissionConfigurationId
						selectedItem.outcome.authSubmissionFormId = null
					})

					logRocketSetEvent('AddAuthSubmissionPortalIdEvent', {
						worklistName: worklist.value.name,
						authSubmissionFormId: item.outcome?.authSubmissionFormId || '',
						authSubmissionPortalId: item.outcome?.authSubmissionPortalId || '',
						submissionConfigurationId:
							item.outcome?.submissionConfigurationId || '',
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
				case 'status':
					selectedItems.forEach(selectedItem => {
						selectedItem.status = item.status
					})

					logRocketSetEvent('AddStatusEvent', {
						worklistName: worklist.value.name,
						status: item.status,
						payer: item.payer.name,
						plan: item.plan.type,
						procedureCode: item.procedureCode,
					})

					break
			}
		}

		const updateItems = (
			item: ITableWorklistItem,
			values: {
				value: IContactFaxMethod | IGuideline | undefined
				isAdded: boolean
			}
		) => {
			interface ISavingType {
				[key: string]: boolean
			}
			const savingType = item.isSaving as ISavingType
			const keys = Object.keys(savingType)
			const type = keys.find(key => savingType[key]) || ''

			const selectedItemsCopy = JSON.parse(
				JSON.stringify([...selectedWorklistItems.value])
			) as any[]

			setSelectedValue(type, item, selectedItemsCopy, values)

			selectedItemsCopy.forEach(selectedItem => {
				updateItem(selectedItem)
			})
		}

		function onItemUpdate(
			// Update operation returns a cloned item to update
			updateOperation: () => {
				itemCopy: ITableWorklistItem
				values: {
					value: IContactFaxMethod | IGuideline | undefined
					isAdded: boolean
				}
			}
		) {
			const { itemCopy, values } = updateOperation()

			if (!isBulkEditing.value) {
				// Save individual item
				updateItem(itemCopy)
			} else {
				// Save bulk items
				updateItems(itemCopy, values)
			}
		}

		function maintainSelections() {
			const selectedItemsCopy = [...selectedWorklistItems.value]

			// reassign selected items when we refresh because we lose the isSelected value
			for (const itemCopy of selectedItemsCopy) {
				const foundItem = worklistItems.value.find(
					item => item.id === itemCopy.id
				)
				if (foundItem) {
					foundItem.isSelected = true
				}
			}
		}

		function associateRequirements(id: string, item: IWorklistItem) {
			const itemCopy: IWorklistItem = JSON.parse(JSON.stringify(item))
			itemCopy.requirementSet = {
				id,
			}
			updateItem(itemCopy)

			logRocketSetEvent('RequirementSetCreatedEvent', {
				worklistName: worklist.value.name,
				requirementSetId: id,
			})
		}

		watch(formIds, ids => {
			const validIds = ids.filter(id => !!id)
			fetchForms(null, validIds).then(res => {
				formOptions.value = res
			})
		})

		watch(submissionConfigurationIds, ids => {
			const validIds = ids.filter(id => !!id)

			if (validIds) {
				fetchSubmissionConfiguration(validIds).then(res => {
					submissionConfigurationOptions.value = res
				})
			}
		})

		onMounted(() => {
			fetchWorklistDataFilters(worklistId.value).then(data => {
				const statuses = [
					WorklistItemStatus.IN_PROGRESS,
					WorklistItemStatus.IN_REVIEW,
				]
				worklistFilterData.value = { ...data, ...{ statuses } }
			})
			fetchWorklist(worklistId.value).then((wl: IWorklist) => {
				worklist.value = wl
				tempName.value = worklist.value.name

				// Can edit detail if a PA Lead or a Specialist assigned to a worklist
				canEditDetail.value =
					permission.$canEditWorklist() ||
					(permission.$canEditWorklistDetail() &&
						user.value.user?.email === worklist.value.assigneeId)
			})

			fetchPlacesOfService().then(placesOfService => {
				// Fetch and store place of service for all worklist items only once
				store.dispatch('worklist/setPlacesOfService', placesOfService)
			})

			fetchSubmissionConfigurations().then(res => {
				store.dispatch('worklist/setSubmissionConfigurations', res)
			})

			pageLoading.value = true
			scrollToTop()
			getWorklistItems(() => (pageLoading.value = false))
		})

		return {
			worklistTable,
			pageLoading,
			canEditDetail,
			canPublish,
			canDelete,
			canDeleteRequirements,
			canPasteRequirements,
			haveSameStatus,
			worklist,
			isEditingName,
			isPublishing,
			isPasting,
			isDeleting,
			isDeletingRequirements,
			worklistItemStatus,
			headers,
			worklistItems,
			totalItems,
			isLoadingNameChange,
			displayAuthRequirement,
			displayContactMethod,
			selectedWorklistItems,
			isBulkEditing,
			haveSamePayerPlan,
			haveSamePayer,
			isBulkUpdating,
			ActiveStatus,
			formOptions,
			submissionConfigurationOptions,
			worklistFilterData,
			worklistId,
			showCodeDescriptions,

			updatePage,
			getPage,
			updateSort,
			updateSortOrder,
			updateName,
			onToggleEditName,
			onStatusUpdate,
			onPublish,
			onPasteRequirements,
			onDeleteItems,
			onDeleteRequirements,
			onFilterChange,
			onItemSelect,
			onSelectAll,
			onItemUpdate,
			onDeleteRequirementSet,
			onShowCodeDescription,
			associateRequirements,
			onCheckItemRequirement,
			onActiveItem,
		}
	},
})
